<!--
 * @Author       : Hugo
 * @Date         : 2020-07-28 11:53:20
 * @LastEditors  : Hugo
 * @LastEditTime : 2022-02-24 14:23:01
 * @Description  : 
 * @FilePath     : /miaohang/src/components/FunTemplate/TemplateUnit.vue
--> 
<template>
  <div class="com_template_unit">
    <div
      class="new_content_group"
      :class="{isimportant: unitdata.isimportant && 0, isdo: unitdata.isdo && !unitdata.isimportant && 0, both: unitdata.isimportant && unitdata.isdo && 0}"
      v-if="1"
      @click="openTaskBox"
    >
      <div class="color_group"
        v-if="unitdata.colour"
      >
        <template
          v-for="(item,index) in getColorList(unitdata)"
        >
          <div
            class="color"
            :key="index"
            :style="{'border-left-color': item}"
          ></div>
        </template>
        
      </div>
      <div
        class="dot"
        v-show="unitdata.dot"
      ></div>
      <el-popover
        placement="bottom"
        title="标题"
        width="200"
        trigger="click"
        content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
        v-if="0"
      >
        <div
          class="unit_fun_btn cp"
          slot="reference"
        ></div>
      </el-popover>
      <div class="info_group">
        <div class="info_box">
          <template>
            <div class="info cp un_sel">{{unitdata.taskDetail || unitdata.name || unitdata.taskName}}</div>
          </template>
          <el-popover
            placement="right-start"
            trigger="click"
            popper-class="com_template_unit_more_btn"
            :visible-arrow="false"
            v-model="switch_more_btn"
            v-if="more_btn"
          >
            <div 
              class="more_list"
            >
              <div class="list_group un_sel cp"
                @click="switch_more_btn=false;openTagBox();"
              >
                <img src="~@/assets/images/fun_template/more_menu_tag.png" alt="" class="icon">
                <div class="text">设置标签</div>
              </div>
              <div class="list_group un_sel cp"
                @click="switch_more_btn=false;doShare();"
              >
                <img src="~@/assets/images/task/task_share.png" alt="" class="icon">
                <div class="text">分享任务</div>
              </div>

              <div class="list_group un_sel cp"
                v-if="0"
                @click="switch_more_btn=false;"
              >
                <img src="~@/assets/images/fun_template/more_menu_transmit.png" alt="" class="icon">
                <div class="text">转发任务</div>
              </div>
            </div>
            <div class="more_btn cp"
              @click.stop="openMoreMenu();"
              slot="reference"
            ></div>
          </el-popover>
          
        </div>
        <div class="time_box">{{unitdata.updateTime | universalDate}}</div>
      </div>
      <div class="template_info_group">
        <div
          v-if="unitdata.taskType == 2 && unitdata.draft !== 1" 
        ></div>
        <div
          class="avatar_group"
          v-if="(unitdata.taskType != 2 || unitdata.draft === 1) && unitdata.createuserthumbnail"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="unitdata.createusername"
            placement="bottom"
            :open-delay="500"
          >
            <img
              :src="`${$avatar_url}${unitdata.createuserthumbnail}@!small200`"
              alt=""
              class="avatar"
            >
          </el-tooltip>
          <img
            src="~@/assets/images/dialog/new_chat_icon.gif"
            alt=""
            class="new_chat_icon"
            v-show="unitdata.taskchatnum && 0"
          >
        </div>
        <div class="tag_box"
          v-if="!is_sell"
        >
          <div
            class="tag teach"
            v-show="unitdata.taskType == 2 || unitdata.taskType == 3"
          >教</div>
        </div>
        <div
          v-if="is_sell"
          class="check_status"
          :class="[transformCheckStaus(unitdata.checkstatus).class_name]"
        >
          {{transformCheckStaus(unitdata.checkstatus).text}}
        </div>
      </div>
    </div>
    <div
      class="from_group cp"
      @click="openTaskBox"
      v-if="show_from"
    >
      <div class="tag">来源:</div>
      <el-tooltip
        class="item"
        effect="dark"
        :content="unitdata.from"
        placement="bottom-start"
        :open-delay="500"  
      >
        <div class="from">{{unitdata.from}}</div>
      </el-tooltip>
      
    </div>
    <div
      class="content_group cp"
      @click="openTaskBox"
      v-if="0"
    >
      <div class="tag_group" v-show="unitdata.taskType == 2 || unitdata.taskType == 3">
        <div class="tag">教</div>
      </div>
      <div class="content">{{unitdata.name || unitdata.taskName}}</div>
    </div>
    <div
      class="info_group"
      v-if="0"
    >
      <!-- 仅获取edit_task_data -->
      <div class="tag cp" @click="openTagBox"></div>
      <div class="time" v-if="!is_sell">{{unitdata.updateTime | universalDate}}</div>
      <div
        v-if="is_sell"
        class="check_status"
        :class="[transformCheckStaus(unitdata.checkstatus).class_name]"
      >
        {{transformCheckStaus(unitdata.checkstatus).text}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    unitdata:{
      type: Object,
      default: null,
    },
    more_btn:{
      type: Boolean,
      default: true,
    },
    jump: {
      type: Boolean,
      default: false,
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      switch_more_btn: false,
    }
  },
  computed:{
    is_sell(){
      let route = this.$route;
      if(route.params.type == 'sell'){
        return true;
      }else{
        return false;
      }
    },
    // 是否显示来源
    show_from(){
      let status = false;
      let type = this.$route.params.type;
      if(type == 'created' || type == 'receive'){
        status = true;
      }
      return status;
    },
  },
  methods:{
    openMoreMenu(){
      // console.log('openMoreMenu');
    },
    doShare(){
      this.$api.share.doStartShare({data: this.unitdata, type: 1});
    },
    openTagBox(){
      this.$store.commit('set_template_addtag_unit_data', this.unitdata);
      // console.log(this.unitdata)
      this.$emit('openTagBox');
    },
    openTaskBox(){
      // 我销售的需要抹空
      let {type} = this.$route.params;
      let data = {
        taskId: this.unitdata.id,
      }
      if(type == 'purchase'){
        // data.copy = true;
        data.status = 'clear';
      }else if(type == 'sell' ){
        data.status = 'clearuser';
      }
      let config = {
        edit:true, 
        jump: this.jump, 
        draft: this.draft,
        relevancy: this.draft, // 是否加载关联数据
      }
      if(this.draft){
        config.select_target = true;
      }
      // // console.log(this.$route.params);
      // if(this.$dev_sign){
        this.$api.moduleTask.openTask({taskId:data.taskId, config});
      // }else{
      //   this.$store.dispatch('openTaskBox', data);

      // }
    },
    /**
     * @description: 转换审核状态 
     * @param {*} status
     * @return {*}
     */
    transformCheckStaus(status){
      if(!status){
        status = 3;
      }
      let obj = {
        3: {text:'通过', class_name: 'success'},
      }
      return obj[status] || {};
    },
    /**
     * @description: 获取颜色列表
     * @param {*} item
     * @return {*}
     */
    getColorList(item){
      const {colour} = item;
      if(!colour){
        return '';
      }
      const color_list = colour.split(',');
      return color_list;

    },
  },
}
</script>
<style lang="scss" scoped>
.com_template_unit{
  @include bbox;
  width:160px;
  border-radius:10px;
  .new_content_group{
    @include bbox;
    width: 160px;
    height: 140px;
    background: rgba(244, 244, 244, 1);
    border: 1px solid#E8EEFF;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    // margin-left: 10px;
    // margin-bottom: 20px;
    position: relative;
    z-index: 2;
    .color_group{
      @include flex;
      flex-direction: column;
      position: absolute;
      height: 100%;
      width: 0;
      top: 0;
      left: 0px;
      .color{
        flex:1;
        width:5px;
        border: none;
        border-left: 4px solid #FFB038;
        &:first-child{
          border-top-left-radius: 20px;
        }
        &:last-child{
          border-bottom-left-radius: 20px;
        }
        &.placeholder{
          border-left-color: transparent;
        }
      }
    }
    .dot {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 12px;
      height: 12px;
      background: rgba(255, 59, 48, 1);
      border-radius: 50%;
    }
    .tag_group {
      position: absolute;
      z-index: 4;
      top: 0;
      right: 0;
      background: {
        image: url(~@/assets/images/dialog/receive_tag.png);
        size: 100%;
      }
      width: 36px;
      height: 36px;
      .tag {
        font-size: 14px;
        
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 18px;
        position: absolute;
        z-index: 5;
        top: 2px;
        right: 3px;
      }
    }
    .unit_fun_btn{
      @include background(3px 15px);
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 9;
      background-image: url(~@/assets/images/control/fun_more.png);
      top:10px;
      right:2px;
    }
    .info_group {
      @include flexsb;
      @include bbox;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      height: 96px;
      padding: 19px 16px;
      background-color: #fff;
      border-radius: 10px 10px 0 0;
      .info_box{
        @include flexsb;
        @include bbox;
        align-items: flex-start;
        width: 100%;
        flex:1;
        height: 100%;
        padding-right: 13px;
        position: relative;
        .info{
          @include multi_line_intercept(2);
          width: 100%;
          font-size: 12px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 18px;
          text-align: justify;
          text-justify: inter-ideograph;
          text-align-last: left;
          word-break: break-all;
        }
        .more_btn{
          @include background;
          position: absolute;
          width: 16px;
          height: 16px;
          background: {
            image: url(~@/assets/images/control/fun_more.png);
            size: contain;
          }
          right: -8px;
          top: 0;
          z-index: 3;
        }
      }
      .time_box{
        @include bbox;
        padding-left: 7px;
        width: 100%;
        font-size: 12px;
        color:#666;
        border-left: 4px solid #e5e5e5;
        height: 12px;
        line-height: 12px;
      }
      
    }
    .template_info_group{
      @include flexsb;
      @include bbox;
      border-top: 2px solid #f8f8f8;
      height: 40px;
      padding: 0 16px;
      border-radius: 0 0 10px 10px;
      background-color:#fff;
      .avatar_group{
        @include flex;
        .avatar{
          display: block;
          width:26px;
          height: 26px;
          border-radius: 4px;
          overflow: hidden;
        }
        .new_chat_icon{
          margin-left: 8px;
          height: 19px;
          width: 20px;
        }
      }
      .tag_box{
        @include flex;
        justify-content: flex-end;
        .tag{
          font-size: 12px;
          line-height: 19px;
          padding: 0 7px;
          margin-left:4px;
          border-radius: 2px;
          &.teach{
            color: #FF7200;
            background-color: rgba(255, 114, 0, 0.08);
          }
        }
      }
      .check_status{
        font-size:14px;
        color:#05AD00;
      }
    }
    
    &.loadmore{
      @include flex;
      justify-content: center;
      background-color: transparent;
      box-shadow: none;
      border:none;
    }
  }
  .from_group{
    @include flex;
    @include bbox;
    padding-left: 6px;
    width: 100%;
    font-size: 12px;
    padding-top: 10px;
    margin-bottom: 10px;
    .tag{
      flex-shrink: 0;
    }
    .from{
      @include single_line_intercept;
      @include bbox;
      padding-left: 5px;
      width: 100%;
      flex:1;
      color:#1B6FC2;
    }
  }
  .content_group{
    @include bbox;
    padding: 19px 16px;
    background-color: #fff;
    height: 105px;
    position: relative;
    .tag_group {
      position: absolute;
      z-index: 4;
      top: 0;
      right: 0;
      background: {
        image: url(~@/assets/images/dialog/receive_tag.png);
        size: 100%;
      }
      width: 36px;
      height: 36px;
      .tag {
        font-size: 14px;
        
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 18px;
        position: absolute;
        z-index: 5;
        top: 2px;
        right: 3px;
      }
    }
    .content{
      @include multi_line_intercept(4);
      font-size:12px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
      line-height:18px;
      text-align: justify;
      text-justify: inter-ideograph;
      text-align-last: left;
      word-break: break-all;
    }
  }
  .info_group{
    @include flexsb;
    @include bbox;
    padding: 0 16px;
    width:100%;
    height: 35px;
    .time{
      font-size:12px;
      
      font-weight:400;
      color:rgba(153,153,153,1);
    }
    .tag{
      width: 14px;
      height: 18px;
      background: {
        image: url(~@/assets/images/fun_template/tag.png);
      }
    }
    .check_status{
      font-size:14px;
      color:#05AD00;
    }
  }
}
</style>
<style lang="scss" scoped>
.more_list{
  .list_group{
    @include transition;
    @include flex;
    padding: 0 25px;
    &:hover{
      background-color: rgba(0,0,0,0.4);
    }
    .text{
      color: #fff;
      font-size: 14px;
      line-height: 50px;
      padding-left: 13px;
    }
    .icon{
      width: 20px;
      height: 20px;
    }
  }
}

</style>
<style scope>
.com_template_unit_more_btn{
  background-color: #333;
  padding: 0;
  border-radius: 4px;
}
</style>